import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../../assets/scss/datatables.scss";
import { getReport } from "../../service/parse-service";
import ReportTable from "./components/ReportTable";
import TablePagination from "../../components/TablePagination";

const Report = ({ history, location }) => {
  const [loading, setLoading] = useState(false);
  const [reportsData, setReportsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [order, setOrder] = useState({
    column: "",
    direction: "",
  });
  // Pagination
  const [currentPage, setCurrentPage] = useState();
  const [reportsPerPage] = useState(10);
  const [totalReports, setTotalReports] = useState(0);

  const handleOrder = (column) => {
    let direction = "asc";
    if (order.column === column && order.direction === "asc") {
      direction = "desc";
    }
    setOrder({ column, direction });
  };

  useEffect(() => {
    if (currentPage === undefined) return;
    const fetchReportsData = async () => {
      setLoading(true);
      const results = await getReport(
        currentPage,
        reportsPerPage,
        searchQuery,
        order
      );

      setReportsData(results.reports);
      setTotalReports(results.totalCount);

      setLoading(false);
    };
    fetchReportsData();
  }, [currentPage, reportsPerPage, searchQuery, order]);

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Report" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>Report</CardTitle>
                <div className="d-flex mb-3 justify-content-between align-items-center">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                    className="ms-auto"
                  >
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => {
                          let target = e.target;
                          setTimeout(() => {
                            setSearchQuery(target.value);
                          }, 1000);
                        }}
                      />
                    </FormGroup>
                  </Form>
                </div>

                <ReportTable
                  reportsData={reportsData}
                  loading={loading}
                  history={history}
                  handleOrder={handleOrder}
                  order={order}
                />

                <TablePagination
                  dataPerPage={reportsPerPage}
                  totalData={totalReports}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  history={history}
                  location={location}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Report;
