import { useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Loading from "../../../components/Common/Loading";
import { Input, Button, Spinner, Form, FormGroup } from "reactstrap";
import { addAuthorityUsersToAgent } from "../../../service/parse-service";

const TutorsTable = ({
  performers,
  loading,
  agentId,
  setAuthorityUserVariable,
  authorityUserVariable,
  setPSearchQuery,
  performerVariable,
  setPerformerVariable,
}) => {
  const tableHeight = loading ? "50vh" : "auto";
  const [selectedPerformer, setSelectedPerformer] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const toggleSelectCheckbox = (performer) => {
    if (selectedPerformer.includes(performer)) {
      setSelectedPerformer(selectedPerformer.filter((id) => id !== performer));
    } else {
      setSelectedPerformer([...selectedPerformer, performer]);
    }
  };

  const handleAddToAuthorityUsers = async () => {
    setBtnLoading(true);

    let selectedPerformerIds = selectedPerformer.map((p) => p.objectId);

    await addAuthorityUsersToAgent(agentId, selectedPerformerIds);

    setAuthorityUserVariable(!authorityUserVariable);
    setPerformerVariable(!performerVariable);
    setSelectedPerformer([]);

    setBtnLoading(false);
  };

  return (
    <>
      <div className="d-flex mb-3 justify-content-end align-items-center">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <FormGroup className="ms-auto">
            <Input
              type="text"
              placeholder="Search"
              onChange={(e) => {
                let target = e.target;
                setTimeout(() => {
                  setPSearchQuery(target.value);
                }, 1000);
              }}
            />
          </FormGroup>
        </Form>
      </div>

      <div className="table-rep-plugin">
        <div
          className="table-responsive mb-0"
          data-pattern="priority-columns"
          style={{
            position: "relative",
            height: tableHeight,
          }}
        >
          <Table className="table table-striped table-bordered">
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Username</Th>
                <Th>Email</Th>
              </Tr>
            </Thead>

            {loading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 500,
                  height: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading />
              </div>
            ) : (
              <Tbody>
                {performers.map((performer) => (
                  <Tr key={performer.objectId}>
                    <Td>
                      <Input
                        type="checkbox"
                        onChange={() => toggleSelectCheckbox(performer)}
                        checked={selectedPerformer.includes(performer)}
                      />
                    </Td>
                    <Td>{performer.nickname}</Td>
                    <Td>{performer.email}</Td>
                  </Tr>
                ))}
              </Tbody>
            )}
          </Table>

          {!loading && (
            <div className="d-flex justify-content-center">
              <Button
                color="success"
                onClick={handleAddToAuthorityUsers}
                disabled={selectedPerformer.length === 0}
              >
                {btnLoading ? (
                  <Spinner size="sm" color="light" />
                ) : (
                  "Add to Authority Users"
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TutorsTable;
