import { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Loading from "../../../components/Common/Loading";
import { getTutor } from "../../../service/parse-service";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Parse from "parse";
import ImageModal from "../../../components/Modal/ImageModal";
import Swal from "sweetalert2";
import { assignAsTutor } from "../../../service/parse-service";

const PendingTutorDetails = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [tutor, setTutor] = useState(null);
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    image: "",
  });

  const id =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const tutorData = await getTutor(id);
        if (tutorData) setTutor(tutorData);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  const handleAssignAsTutor = (tutorId) => {
    Swal.fire({
      title: "Are you sure you want to confirm?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await assignAsTutor(tutorId);
          Swal.fire(response);
          history.push("/pending-performers");
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  const handleRejectTutor = async (tutorId) => {
    Swal.fire({
      title: "Are you sure you want to reject?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Reject",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const user = await new Parse.Query(Parse.User).get(tutorId);
          const totalApplicationCount = await new Parse.Query(
            "TutorApplication"
          ).count();
          const createFakeUserPointer = {
            __type: "Pointer",
            className: "_User",
            objectId: tutorId + `old${totalApplicationCount}`,
          };
          const tutorApp = await new Parse.Query("TutorApplication")
            .equalTo("user", user)
            .first();
          tutorApp.set("user", createFakeUserPointer);
          tutorApp.set("isDelete", true);
          tutorApp.set("isRejected", true);
          await tutorApp.save();
          Swal.fire("Rejected!", "Tutor has been rejected.", "success");
          history.push("/pending-performers");
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <ImageModal imageModal={imageModal} setImageModal={setImageModal} />
        <Breadcrumbs
          title="Dashboard"
          previousTutorPending="Pending Performers"
          breadcrumbItem="Pending Performer Detail"
        />
        {loading && <Loading loading={loading} />}
        {!loading && tutor && (
          <>
            <Link to="/pending-performers">
              <button
                type="button"
                className="btn btn-md btn-primary waves-effect waves-light mb-3"
              >
                Back
              </button>
            </Link>
            <Row>
              <Col lg={5}>
                <Card>
                  <CardBody>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Profile Image :</Col>
                      <Col lg={8}>
                        <div
                          className="d-flex flex-row"
                          style={{
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                            width: "100%",
                          }}
                        >
                          {tutor.photos &&
                            tutor.photos.map((photo, index) => (
                              <div
                                key={index}
                                className="d-flex flex-column"
                                style={{ marginRight: "15px" }}
                              >
                                <img
                                  className="mb-5"
                                  style={{
                                    width: "150px",
                                    height: "150px",
                                    borderRadius: "20px",
                                  }}
                                  src={photo?.url}
                                  alt={photo.name}
                                />
                              </div>
                            ))}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Fullname :</Col>
                      <Col lg={8}>{tutor.fullname || "-"}</Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Username :</Col>
                      <Col lg={8}>{tutor.user?.nickname || "-"}</Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Email :</Col>
                      <Col lg={8}>{tutor.mail || "-"}</Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Tags :</Col>
                      <Col lg={8}>
                        {tutor.user?.tags?.map((tag) => tag).join(", ") || "-"}
                      </Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Mobile :</Col>
                      <Col lg={8}>{tutor.mobile || "-"}</Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Birth Date :</Col>
                      <Col lg={8}>{tutor.dob || "-"}</Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Gender :</Col>
                      <Col lg={8}>
                        {tutor.gender === "female" ? "Female" : "Male" || "-"}
                      </Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Paypal :</Col>
                      <Col lg={8}>{tutor.paypal || "-"}</Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>TRC20 :</Col>
                      <Col lg={8}>{tutor.trc20 || "-"}</Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Kuna :</Col>
                      <Col lg={8}>{tutor.kuna || "-"}</Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Payoneer :</Col>
                      <Col lg={8}>{tutor.payoneer || "-"}</Col>
                    </Row>

                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        title="Confirm"
                        className="btn btn-md btn-primary waves-effect waves-light d-flex justify-content-center align-items-center ms-2"
                        onClick={() =>
                          handleAssignAsTutor(tutor?.user?.objectId)
                        }
                      >
                        <i className="fas fa-check"></i>
                      </button>
                      <button
                        type="button"
                        title="Reject"
                        key={tutor?.user?.objectId}
                        onClick={() => handleRejectTutor(tutor?.user?.objectId)}
                        className="btn btn-md btn-danger waves-effect waves-light d-flex justify-content-center align-items-center ms-2"
                      >
                        <i className="fas fa-solid fa-trash"></i>
                      </button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default PendingTutorDetails;
