import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../../assets/scss/datatables.scss";
import { getGeneralReport } from "../../service/parse-service";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import GeneralReportTable from "./GeneralReportTable";

const GeneralReport = () => {
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selectedDates, setSelectedDates] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [order, setOrder] = useState({
    column: "",
    direction: "",
  });

  const handleOrder = (column) => {
    let direction = "asc";
    if (order.column === column && order.direction === "asc") {
      direction = "desc";
    }
    setOrder({ column, direction });
  };

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const dayDifference =
      Math.abs(dateRange[0].endDate - dateRange[0].startDate) /
        (1000 * 60 * 60 * 24) +
        1 || 1;
    const dates = [...Array(dayDifference)].map((_, i) => {
      const date = new Date(dateRange[0].endDate - 3600 * 1000 * 24 * i);
      const newDate = new Date(
        date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)
      );
      return newDate.toISOString().split("T")[0];
    });
    setSelectedDates(dates);
  }, [dateRange]);

  useEffect(() => {
    if (selectedDates) {
      setLoading(true);
      (async () => {
        try {
          let data = await Promise.all(
            selectedDates.map(async (date) => {
              var tomorrow = new Date(date);
              tomorrow.setDate(tomorrow.getDate() + 1);
              let response = await getGeneralReport(
                tomorrow.toISOString().split("T")[0],
                date
              );

              let result = {};
              Object.keys(response).forEach((key) => {
                if (response[key]?.length > 0) {
                  response[key]?.forEach((item) => {
                    result[
                      String(item?.objectId).replace("-", "").replace("|", "") +
                        key.replace(/\s/g, "")
                    ] = item?.totalAmount
                      ? `${item.count} (${item?.totalAmount.toFixed(2)})`
                      : item?.count;
                  });
                }
              });

              return { DATES: date, ...result };
            })
          );

          // Sort data
          if (order.column) {
            data = data.sort((a, b) => {
              const isReversed = order.direction === "asc" ? 1 : -1;

              if (a[order.column] === undefined || a[order.column] === null) {
                return -1 * isReversed;
              }

              if (b[order.column] === undefined || b[order.column] === null) {
                return 1 * isReversed;
              }

              return a[order.column] > b[order.column]
                ? 1 * isReversed
                : -1 * isReversed;
            });
          }

          setReportData(data);
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [selectedDates, order]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const resetDateFilter = () => {
    setDateRange([
      {
        startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setOrder({
      column: "",
      direction: "",
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="General Report" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>General Report</CardTitle>
                <div className="d-flex justify-content-start mb-3">
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    className="me-2"
                  >
                    <DropdownToggle color="primary">
                      {dateRange[0].startDate === null
                        ? "Date"
                        : `${dateRange[0].startDate.toLocaleDateString()} - ${dateRange[0].endDate.toLocaleDateString()}`}
                      <i className="fas fa-caret-down mx-1"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DateRange
                        editableDateInputs={true}
                        onChange={(item) => setDateRange([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                        minDate={new Date(`01-01-${currentYear}`)}
                        maxDate={new Date(`12-31-${currentYear}`)}
                      />
                    </DropdownMenu>
                  </Dropdown>
                  <Button color="primary" onClick={resetDateFilter}>
                    <span className="me-2">Reset</span>
                    <i className="fas fa-redo"></i>
                  </Button>
                </div>

                <GeneralReportTable
                  loading={loading}
                  reportData={reportData}
                  order={order}
                  handleOrder={handleOrder}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default GeneralReport;
