import { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Loading from "../../../components/Common/Loading";
import {
  getReportDetail,
  userBan,
  userUnBan,
  deleteUser,
} from "../../../service/parse-service";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";

const ReportDetails = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [reportDetail, setReportDetails] = useState(null);

  const id =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const reportData = await getReportDetail(id);
        if (reportData) setReportDetails(reportData);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  const handleBan = (userId) => {
    Swal.fire({
      title: "Are you sure you want to ban this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ban",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire("User Banned.");
          await userBan(userId);
          window.location.reload();
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  const handleUnban = (userId) => {
    Swal.fire({
      title: "Are you sure you want to unban this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Unban",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire("User Unbanned.");
          await userUnBan(userId);
          window.location.reload();
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  const handleDeleteUser = (userId) => {
    Swal.fire({
      title: "Are you sure you want to delete this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire("User deleted.");
          await deleteUser(userId);
          history.push("/report");
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Breadcrumbs
          title="Dashboard"
          previousReport="Report"
          breadcrumbItem="Report Detail"
        />
        {loading && <Loading loading={loading} />}
        {!loading && reportDetail && (
          <>
            <button
              type="button"
              className="btn btn-md btn-primary waves-effect waves-light mb-3"
              onClick={() => history.goBack()}
            >
              Back
            </button>
            <Row>
              <Col lg={5}>
                <Card>
                  <CardBody>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Reporting User :</Col>
                      <Col lg={8}>{reportDetail?.user?.nickname || "-"}</Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Reported User :</Col>
                      <Col lg={8}>
                        {reportDetail?.reported_user_data?.nickname || "-"}
                      </Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Reason :</Col>
                      <Col lg={8}>{reportDetail?.reason || "-"}</Col>
                    </Row>
                    <div className="text-center mt-4">
                      <button
                        onClick={() =>
                          handleDeleteUser(reportDetail?.user?.objectId)
                        }
                        type="button"
                        title="Delete User"
                        className="btn btn-danger waves-effect waves-light ms-2"
                      >
                        <i className="fas fa-solid fa-trash"></i>
                      </button>

                      <button
                        onClick={() => {
                          reportDetail?.user?.isBanned
                            ? handleUnban(reportDetail?.user?.objectId)
                            : handleBan(reportDetail?.user?.objectId);
                        }}
                        type="button"
                        title={
                          reportDetail?.user?.isBanned
                            ? "Unban User"
                            : "Ban User"
                        }
                        className="btn btn-danger waves-effect waves-light ms-2"
                      >
                        {reportDetail?.user?.isBanned ? (
                          <i className="fas fa-solid fa-lock-open"></i>
                        ) : (
                          <i className="fas fa-solid fa-lock"></i>
                        )}
                      </button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default ReportDetails;
