import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { fetchCurrentUser } from "../../service/parse-service";
// //Import Scrollbar
import SimpleBar from "simplebar-react";
// import {
//   Dropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
// } from "reactstrap";
// // MetisMenu
// import MetisMenu from "metismenujs";
// import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const [currentUserData, setCurrentUserData] = useState(null);
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [liveDropDown, setLiveDropDown] = useState(false)

  // const toggle = () => setDropdownOpen((prevState) => !prevState);
  // const liveToggle = () => setLiveDropDown((prevState) => !prevState)

  useEffect(() => {
    fetchCurrentUser().then((currentUser) => {
      setCurrentUserData(currentUser?.get("username"));
    });
    // eslint-disable-next-line
  }, []);

  const ref = useRef();
  // const location = useLocation();
  // const history = useHistory();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  // useEffect(() => {
  //   const pathName = props.location.pathname;
  //   take the pathname and split it by '/'
  //   const pathName = location.pathname.split("/");

  //   const initMenu = () => {
  //     new MetisMenu("#side-menu");
  //     let matchingMenuItem = null;
  //     const ul = document.getElementById("side-menu");
  //     const items = ul.getElementsByTagName("a");
  //     // for (let i = 0; i < items.length; ++i) {
  //     //   if (location.pathmame === items[i].pathname) {
  //     //     matchingMenuItem = items[i];
  //     //     break;
  //     //   }
  //     // }
  //     if (matchingMenuItem) {
  //       activateParentDropdown(matchingMenuItem);
  //     }
  //   };
  //   initMenu();
  // }, [location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  // function scrollElement(item) {
  //   if (item) {
  //     const currentPosition = item.offsetTop;
  //     if (currentPosition > window.innerHeight) {
  //       ref.current.getScrollElement().scrollTop = currentPosition - 300;
  //     }
  //   }
  // }

  // function activateParentDropdown(item) {
  //   item.classList.add("active");
  //   const parent = item.parentElement;
  //   const parent2El = parent.childNodes[1];
  //   if (parent2El && parent2El.id !== "side-menu") {
  //     parent2El.classList.add("mm-show");
  //   }

  //   if (parent) {
  //     parent.classList.add("mm-active");
  //     const parent2 = parent.parentElement;

  //     if (parent2) {
  //       parent2.classList.add("mm-show"); // ul tag

  //       const parent3 = parent2.parentElement; // li tag

  //       if (parent3) {
  //         parent3.classList.add("mm-active"); // li
  //         parent3.childNodes[0].classList.add("mm-active"); //a
  //         const parent4 = parent3.parentElement; // ul
  //         if (parent4) {
  //           parent4.classList.add("mm-show"); // ul
  //           const parent5 = parent4.parentElement;
  //           if (parent5) {
  //             parent5.classList.add("mm-show"); // li
  //             parent5.childNodes[0].classList.add("mm-active"); // a tag
  //           }
  //         }
  //       }
  //     }
  //     scrollElement(item);
  //     return false;
  //   }
  //   scrollElement(item);
  //   return false;
  // }

  return (
    <>
      <SimpleBar className="vertical-simplebar" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            {currentUserData !== "admin" ? (
              <>
                <li>
                  <Link to="/performers" className=" waves-effect">
                    <i className="fas fa-chalkboard-teacher font-size-16 align-middle me-1"></i>{" "}
                    <span>{props.t("Performers")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/performer-earning" className=" waves-effect">
                    <i className="fas fa-dollar-sign font-size-16 align-middle ms-1"></i>{" "}
                    <span>{props.t("Performer Earnings")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/activity" className=" waves-effect">
                    <i className="bx bxs-phone-call font-size-16 align-middle me-1"></i>{" "}
                    <span>{props.t("Activity")}</span>
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to="/#" className="waves-effect">
                    <i className="mdi mdi-monitor-dashboard font-size-19 align-middle me-1"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/users" className=" waves-effect">
                    <i className="fas fa-users font-size-16 align-middle me-1"></i>{" "}
                    <span>{props.t("Users")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/agents" className=" waves-effect">
                    <i className="fas fa-users font-size-16 align-middle me-1"></i>{" "}
                    <span>{props.t("Agents")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/performers" className=" waves-effect">
                    <i className="fas fa-chalkboard-teacher font-size-16 align-middle me-1"></i>{" "}
                    <span>{props.t("Performers")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/performer-earning" className=" waves-effect">
                    <i className="fas fa-dollar-sign font-size-16 align-middle ms-1"></i>{" "}
                    <span>{props.t("Performer Earnings")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/pending-performers" className=" waves-effect">
                    <i className="fas fa-user-clock font-size-16 align-middle me-1"></i>{" "}
                    <span>{props.t("Pending Performers")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/activity" className=" waves-effect">
                    <i className="bx bxs-phone-call font-size-16 align-middle me-1"></i>{" "}
                    <span>{props.t("Activity")}</span>
                  </Link>
                </li>

                {/* <li>
                  <Link to="/payments" className=" waves-effect">
                    <i className="fas fa-credit-card font-size-16 align-middle me-1"></i>{" "}
                    <span>{props.t("Payment")}</span>
                  </Link>
                </li> */}

                <li>
                  <Link to="/ticket" className=" waves-effect">
                    <i className="mdi mdi-help-rhombus-outline font-size-22 align-middle me-1"></i>{" "}
                    <span>{props.t("Ticket")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/report" className="">
                    <i className="bx bxs-user-detail font-size-20 align-middle px-1  me-1"></i>{" "}
                    <span>{props.t("Reported Users")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/general-report" className=" waves-effect">
                    <i className="bx bxs-report font-size-18 align-middle px-1 me-1"></i>{" "}
                    <span>{props.t("General Report")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/sales-report" className="waves-effect">
                    <i className="mdi mdi-sale font-size-18 align-middle px-1 me-1"></i>{" "}
                    <span>{props.t("Sales Report")}</span>
                  </Link>
                </li>

                {/* <Dropdown
                  isOpen={liveDropDown}
                  toggle={liveToggle}
                  className="sidebar-dropdown">

                  <DropdownToggle className="sidebar-dropdownToggle" color="white">
                    <i className="fas fa-solid fa-folder-open me-3"></i> <span className="sidebar-dropdownTitle">Live Reports</span>
                  </DropdownToggle>
                  <DropdownMenu className="sidebar-dropDownMenu">

                    <DropdownItem>
                      <li>
                        <Link to="/user-purchase-reports" className="" >
                          <i className="fas fa-info font-size-16 align-middle px-1"></i>{" "}
                          <span>{props.t("User Purchase")}</span>
                        </Link>
                      </li>
                    </DropdownItem>

                    <DropdownItem>
                      <li>
                        <Link to="/user-purchase-country-reports" className="" >
                          <i className="fas fa-info font-size-16 align-middle px-1"></i>{" "}
                          <span>{props.t("User Purchase Country")}</span>
                        </Link>
                      </li>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown> */}

                {/* <div style={liveDropDown ? { marginTop: "60px" } : {}}>
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    className="sidebar-dropdown">


                    <DropdownToggle className="sidebar-dropdownToggle" color="white">
                      <i className="fas fa-solid fa-folder-open me-3"></i> <span className="sidebar-dropdownTitle">Reports</span>
                    </DropdownToggle>
                    <DropdownMenu className="sidebar-dropDownMenu">

                      <DropdownItem >
                        <li>
                          <Link to="/report" className="">
                            <i className="fas fa-info font-size-16 align-middle px-1 me-1"></i>{" "}
                            <span>{props.t("Reported Users")}</span>
                          </Link>
                        </li>
                      </DropdownItem>

                      <DropdownItem>
                        <li>
                          <Link to="/user-reports" className=" waves-effect">
                            <i className="fas fa-info font-size-16 align-middle px-1 me-1"></i>{" "}
                            <span>{props.t("User Reports")}</span>
                          </Link>
                        </li>
                      </DropdownItem>

                      <DropdownItem>
                        <li>
                          <Link to="/user-purchases" className=" waves-effect">
                            <i className="fas fa-info font-size-16 align-middle px-1 me-1"></i>{" "}
                            <span>{props.t("User Purchases")}</span>
                          </Link>
                        </li>
                      </DropdownItem>

                      <DropdownItem>
                        <li>
                          <Link to="/user-country" className=" waves-effect">
                            <i className="fas fa-info font-size-16 align-middle px-1 me-1"></i>{" "}
                            <span>{props.t("User Country")}</span>
                          </Link>
                        </li>
                      </DropdownItem>

                      <DropdownItem>

                        <li>
                          <Link to="/user-country-purchases" className=" waves-effect">
                            <i className="fas fa-info font-size-16 align-middle px-1 me-1"></i>{" "}
                            <span>{props.t("User Country Purchases")}</span>
                          </Link>
                        </li>
                      </DropdownItem>

                      <DropdownItem>

                        <li>
                          <Link to="/general-report" className=" waves-effect">
                            <i className="fas fa-info font-size-16 align-middle px-1 me-1"></i>{" "}
                            <span>{props.t("General Report")}</span>
                          </Link>
                        </li>
                      </DropdownItem>

                    </DropdownMenu>
                  </Dropdown>
                </div> */}
              </>
            )}
            {/* <li className="menu-title">{props.t("Components")}</li> */}
          </ul>
        </div>
      </SimpleBar>
    </>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(SidebarContent);
