import { useState, useEffect } from "react";
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Swal from "sweetalert2";
import { deleteTutor, fetchCurrentUser } from "../../../service/parse-service";
import Loading from "../../../components/Common/Loading";
import TutorsTr from "./TutorsTr";

const TutorsTable = ({
  tutorsData,
  loading,
  totalEarnings,
  totalPayments,
  handleOrder,
  order,
  setImageModal,
  history,
}) => {
  const [currentUserData, setCurrentUserData] = useState(null);

  useEffect(() => {
    fetchCurrentUser().then((currentUserData) => {
      setCurrentUserData(currentUserData);
    });
    // eslint-disable-next-line
  }, []);

  const handleDeleteUser = (userId) => {
    Swal.fire({
      title: "Are you sure you want to delete this performer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire("Performer deleted.");
          await deleteTutor(userId);
          window.location.reload();
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  return (
    <>
      <div className="table-rep-plugin">
        <div
          className="table-responsive mb-0 "
          data-pattern="priority-columns"
          style={{
            position: "relative",
            height: "100vh",
          }}
        >
          <Table className="table table-striped table-bordered">
            <Thead>
              <Tr>
                <Th>Image</Th>
                <Th>Username</Th>
                <Th>Gender</Th>
                <Th
                  onClick={() => handleOrder("email")}
                  style={{ cursor: "pointer" }}
                >
                  Email
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "email" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "email" &&
                          order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th
                  onClick={() => handleOrder("createdAt")}
                  style={{ cursor: "pointer" }}
                >
                  Registration Date
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "createdAt" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "createdAt" &&
                          order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th
                  onClick={() => handleOrder("numberOfCallsAnswered")}
                  style={{ cursor: "pointer" }}
                >
                  Answered{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "numberOfCallsAnswered" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "numberOfCallsAnswered" &&
                          order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th
                  onClick={() => handleOrder("numberOfCallsMissed")}
                  style={{ cursor: "pointer" }}
                >
                  Missed{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "numberOfCallsMissed" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "numberOfCallsMissed" &&
                          order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th
                  onClick={() => handleOrder("callAnswerRate")}
                  style={{ cursor: "pointer" }}
                >
                  Rate{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "callAnswerRate" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "callAnswerRate" &&
                          order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th
                  onClick={() => handleOrder("totalEarning")}
                  style={{ cursor: "pointer" }}
                >
                  Total Earned{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "totalEarning" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "totalEarning" &&
                          order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th
                  onClick={() => handleOrder("earning")}
                  style={{ cursor: "pointer" }}
                >
                  Total Payments{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "earning" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "earning" &&
                          order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th>Available</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 500,
                  height: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading />
              </div>
            ) : (
              <Tbody>
                {tutorsData.totalCount !== 0 && (
                  <Tr>
                    <Th></Th>
                    <Th></Th>
                    <Th></Th>
                    <Th></Th>
                    <Th></Th>
                    <Th></Th>
                    <Th></Th>
                    <Th></Th>
                    <Th className="fw-bold">$ {totalEarnings.toFixed(2)}</Th>
                    <Th className="fw-bold">$ {totalPayments.toFixed(2)}</Th>
                    <Th></Th>
                  </Tr>
                )}

                {tutorsData?.tutors?.map((tutor, index) => (
                  <TutorsTr
                    key={index}
                    tutor={tutor}
                    setImageModal={setImageModal}
                    handleDeleteUser={handleDeleteUser}
                    currentUserData={currentUserData}
                    history={history}
                  />
                ))}
              </Tbody>
            )}
          </Table>
        </div>
      </div>
    </>
  );
};

export default TutorsTable;
