import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Swal from "sweetalert2";
import Loading from "../../../components/Common/Loading";
import TicketTr from "./TicketTr";

const TicketTable = ({
  ticketsData,
  loading,
  handleOrder,
  order,
  setImageModal,
  history,
}) => {
  return (
    <>
      <div className="table-rep-plugin">
        <div
          className="table-responsive mb-0 "
          data-pattern="priority-columns"
          style={{
            position: "relative",
            height: "50vh",
          }}
        >
          <Table className="table table-striped table-bordered">
            <Thead>
              <Tr>
                <Th>Image</Th>
                <Th>Username</Th>
                <Th>Gender</Th>
                <Th>Email</Th>
                <Th
                  onClick={() => handleOrder("createdAt")}
                  style={{ cursor: "pointer" }}
                >
                  Ticket Date{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "createdAt" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "createdAt" &&
                          order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th>Ticket Status</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 500,
                  height: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading />
              </div>
            ) : (
              <Tbody>
                {ticketsData?.map((ticket) => (
                  <TicketTr
                    key={ticket.objectId}
                    ticket={ticket}
                    setImageModal={setImageModal}
                    history={history}
                  />
                ))}
              </Tbody>
            )}
          </Table>
        </div>
      </div>
    </>
  );
};

export default TicketTable;
