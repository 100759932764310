import { Redirect } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/Dashboard";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Users related pages
import Users from "../pages/Users/Users";
import UserDetails from "../pages/Users/components/UserDetails";
import Ticket from "../pages/Ticket/Ticket";
import TicketDetail from "../pages/Ticket/components/TicketDetail";
import Payments from "../pages/Payments/Payments";
import Tutors from "../pages/Tutors/Tutors";
import TutorsDetails from "../pages/Tutors/components/TutorsDetails";
import ApplicationDetail from "../pages/Tutors/components/ApplicationDetail";
import TutorEarning from "../pages/TutorEarning/TutorEarning";
import TutorEarningDetail from "../pages/TutorEarning/components/TutorEarningDetail";
import PendingTutors from "../pages/PendingTutors/PendingTutors";
import PendingTutorDetails from "../pages/PendingTutors/components/PendingTutorDetails";
import Activity from "../pages/Activity/Activity";
import Report from "../pages/Report/Report";
import UserHistory from "../pages/Users/components/UserHistory";
import ReportDetails from "../pages/Report/components/ReportDetails";
import MissedCall from "../pages/Activity/MissedCall";
import Agents from "../pages/Agents/Agents";
import AgentDetails from "../pages/Agents/AgentDetails";

//Analytics
// import UserReports from "../pages/Analytics/UserReports";
// import UserPurchases from "../pages/Analytics/UserPurchases";
// import UserCountry from "../pages/Analytics/UserCountry";
// import UserCountryPurchases from "../pages/Analytics/UserCountryPurchases";
// import UserPurchaseReports from "../pages/LiveReports/UserPurchaseReports";
// import UserPurchaseCountryReports from "../pages/LiveReports/UserPurchaseCountryReports";
import GeneralReport from "../pages/Analytics/GeneralReport";
import SalesReport from "../pages/SalesReport/SalesReport";

const userRoutes = [
  { path: "/dashboard", component: Dashboard, visibleForAllUser: false },
  { path: "/users", component: Users, visibleForAllUser: false },
  { path: "/ticket", component: Ticket, visibleForAllUser: false },
  { path: "/ticket/:id", component: TicketDetail, visibleForAllUser: false },
  { path: "/payments", component: Payments, visibleForAllUser: false },
  { path: "/users/:id", component: UserDetails, visibleForAllUser: false },
  {
    path: "/users/history/:id",
    component: UserHistory,
    visibleForAllUser: false,
  },
  { path: "/report", component: Report, visibleForAllUser: false },
  { path: "/report/:id", component: ReportDetails, visibleForAllUser: false },
  {
    path: "/pending-performers",
    component: PendingTutors,
    visibleForAllUser: false,
  },
  {
    path: "/pending-performers/:id",
    component: PendingTutorDetails,
    visibleForAllUser: false,
  },
  {
    path: "/application-detail/:id",
    component: ApplicationDetail,
    visibleForAllUser: false,
  },
  //Both agency and admin
  { path: "/performers", component: Tutors, visibleForAllUser: true },
  {
    path: "/performers-details/:id",
    component: TutorsDetails,
    visibleForAllUser: true,
  },
  {
    path: "/performer-earning",
    component: TutorEarning,
    visibleForAllUser: true,
  },
  {
    path: "/performer-earning-detail/:id",
    component: TutorEarningDetail,
    visibleForAllUser: true,
  },
  { path: "/activity", component: Activity, visibleForAllUser: true },
  { path: "/missed-call", component: MissedCall, visibleForAllUser: true },
  // { path: "/user-reports", component: UserReports, visibleForAllUser: false },
  // { path: "/user-purchases", component: UserPurchases, visibleForAllUser: false },
  // { path: "/user-country", component: UserCountry, visibleForAllUser: false },
  // { path: "/user-country-purchases", component: UserCountryPurchases, visibleForAllUser: false },
  // { path: "/user-purchase-reports", component: UserPurchaseReports, visibleForAllUser: false },
  // { path: "/user-purchase-country-reports", component: UserPurchaseCountryReports, visibleForAllUser: false },
  {
    path: "/general-report",
    component: GeneralReport,
    visibleForAllUser: false,
  },
  {
    path: "/sales-report",
    component: SalesReport,
    visibleForAllUser: false,
  },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
  { path: "/agents", component: Agents, visibleForAllUser: false },
  {
    path: "/agents/:id",
    component: AgentDetails,
    visibleForAllUser: false,
  },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { userRoutes, authRoutes };
