import { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Loading from "../../../components/Common/Loading";
import { getTutorApplicationDetail } from "../../../service/parse-service";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";

const ApplicationDetail = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [data, setData] = useState({
    columns: [
      {
        label: "Username",
        field: "fullname",
        sort: "asc",
        width: 250,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 100,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 200,
      },
      {
        label: "Birth Date",
        field: "birthDate",
        sort: "asc",
        width: 1300,
      },
      {
        label: "Gender",
        field: "gender",
        sort: "asc",
        width: 1300,
      },
      {
        label: "Known Languages",
        field: "knownLanguages",
        sort: "asc",
        width: 1300,
      },
    ],
  });

  const id =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let mappedResult = [];
        let csvData = [];
        const tutorData = await getTutorApplicationDetail(id);
        mappedResult = tutorData.map((tutor) => {
          return {
            fullname: tutor?.fullname,
            email: tutor?.mail,
            knownLanguages: tutor?.speaking_languages_JSON
              ?.map((language) => language.name)
              .join(", "),
            mobile: tutor?.mobile,
            birthDate: tutor?.dob,
            gender: tutor?.gender === "female" ? "F" : "M",
          };
        });
        csvData = tutorData.map((tutor) => {
          return {
            tutorname: tutor?.fullname,
            date: new Date(tutor?.startTime?.iso).toLocaleString(),
            interviewer: tutor?.user?.nickname,
            totalSeconds: tutor?.total_seconds,
            totalMinutes: tutor?.total_minutes,
            spentCredits: isNaN(tutor?.total_minutes)
              ? "-"
              : `${tutor?.total_minutes * 60} Coins`,
            tutorEarn: isNaN(tutor?.amount) ? "-" : `$ ${tutor?.amount}`,
            reason: tutor?.isCall === true ? "Session" : "Tip",
          };
        });
        mappedResult = mappedResult.filter((item) => item);
        csvData = mappedResult.filter((item) => item);
        setCsvData(csvData);
        setData((prevState) => ({
          ...prevState,
          rows: mappedResult,
        }));
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Breadcrumbs
          title="Dashboard"
          previousTutors="Performers"
          breadcrumbItem="Performer Application Detail"
        />

        <button
          type="button"
          className="btn btn-md btn-primary waves-effect waves-light mb-3"
          onClick={() => history.goBack()}
        >
          Back
        </button>

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex justify-content-end mb-2">
                  <CSVLink data={csvData} filename={"users.csv"}>
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      <i className="bx bx-download font-size-16 align-middle me-2"></i>{" "}
                      Download
                    </button>
                  </CSVLink>
                </div>
                {loading && <Loading loading={loading} />}
                {!loading && (
                  <MDBDataTable
                    exportToCSV
                    hover
                    responsive
                    striped
                    bordered
                    data={data}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ApplicationDetail;
