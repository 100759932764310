import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

import { ToastContainer } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../../assets/scss/datatables.scss";
import { getTutorApplication } from "../../service/parse-service";

import PendingTutorTable from "./components/PendingTutorTable";
import TablePagination from "../../components/TablePagination";

const PendingTutors = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [pendingTutorsData, setPendingTutorsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [order, setOrder] = useState({
    column: "",
    direction: "",
  });
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pendingTutorsPerPage] = useState(10);
  const [totalPendingTutors, setTotalPendingTutors] = useState(0);

  const handleOrder = (column) => {
    let direction = "asc";
    if (order.column === column && order.direction === "asc") {
      direction = "desc";
    }
    setOrder({ column, direction });
  };

  useEffect(() => {
    const fetchPendingTutors = async () => {
      setLoading(true);
      const results = await getTutorApplication(
        order,
        searchQuery,
        currentPage,
        pendingTutorsPerPage
      );

      setPendingTutorsData(results.pendingTutors);
      setTotalPendingTutors(results.totalCount);
      setLoading(false);
    };
    fetchPendingTutors();
  }, [order, searchQuery, currentPage, pendingTutorsPerPage]);

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Pending Performers" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle className="mb-3">Pending Performers </CardTitle>

                <div className="d-flex mb-3 justify-content-between align-items-center">
                  <Form
                    className="ms-auto"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => {
                          let target = e.target;
                          setTimeout(() => {
                            setSearchQuery(target.value);
                          }, 1000);
                        }}
                      />
                    </FormGroup>
                  </Form>
                </div>

                <PendingTutorTable
                  pendingTutorsData={pendingTutorsData}
                  loading={loading}
                  handleOrder={handleOrder}
                  order={order}
                  history={history}
                />

                <TablePagination
                  dataPerPage={pendingTutorsPerPage}
                  totalData={totalPendingTutors}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PendingTutors;
