import { useState } from "react";
import { Link } from "react-router-dom";
import { Tr, Td } from "react-super-responsive-table";

const ReportTr = ({ report, history, handleDeleteReport }) => {
  const [isHoverReportingUser, setIsHoverReportingUser] = useState(false);
  const [isHoverReportedUser, setIsHoverReportedUser] = useState(false);

  const isHoverNameStyles = {
    cursor: isHoverReportingUser || isHoverReportedUser ? "pointer" : "default",
    textDecoration:
      isHoverReportingUser || isHoverReportedUser ? "underline" : "none",
    color: isHoverReportingUser || isHoverReportedUser ? "#007bff" : "",
  };
  return (
    <Tr>
      <Td>{new Date(report.createdAt).toLocaleString()}</Td>
      <Td
        onClick={() => {
          history.push(`/users/${report.user?.objectId}`);
        }}
        onMouseEnter={() => setIsHoverReportingUser(true)}
        onMouseLeave={() => setIsHoverReportingUser(false)}
        style={isHoverReportingUser ? isHoverNameStyles : {}}
      >
        {report.user?.nickname}
      </Td>
      <Td
        onClick={() => {
          history.push(`/users/${report.reported_user_data?.objectId}`);
        }}
        onMouseEnter={() => setIsHoverReportedUser(true)}
        onMouseLeave={() => setIsHoverReportedUser(false)}
        style={isHoverReportedUser ? isHoverNameStyles : {}}
      >
        {report.reported_user_data?.nickname}
      </Td>
      <Td>{report.reason}</Td>
      <Td className="d-flex">
        <Link to={`/report/${report.objectId}`}>
          <button
            type="button"
            title="Detail"
            className="btn btn-md btn-primary waves-effect waves-light"
          >
            <i className="bx bxs-show"></i>
          </button>
        </Link>
        <button
          onClick={() => handleDeleteReport(report.objectId)}
          type="button"
          title="Delete User"
          className="btn btn-danger waves-effect waves-light ms-2"
        >
          <i className="fas fa-solid fa-trash"></i>
        </button>
      </Td>
    </Tr>
  );
};

export default ReportTr;
