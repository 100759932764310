import { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Loading from "../../../components/Common/Loading";
import { getSessionHistoryAdmin } from "../../../service/parse-service";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import ImageModal from "../../../components/Modal/ImageModal";
import noUserImage from "../../../assets/images/users/no-user.png";

const UserHistory = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    image: "",
  });
  //const [usersHistoryData, setUsersHistoryData] = useState([]);
  const [data, setData] = useState({
    columns: [
      {
        label: "Image",
        field: "profileImage",
        sort: "asc",
        width: 150,
      },
      {
        label: "Conversation Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Username",
        field: "username",
        sort: "asc",
        width: 150,
      },
      {
        label: "Gain",
        field: "gain",
        sort: "asc",
        width: 270,
      },
      {
        label: "Total Seconds",
        field: "total_seconds",
        sort: "asc",
        width: 270,
      },
      {
        label: "Total Minutes",
        field: "total_minutes",
        sort: "asc",
        width: 270,
      },
      {
        label: "Purchased Credits",
        field: "purchasedCredits",
        sort: "asc",
        width: 270,
      },
      {
        label: "Spent Credits",
        field: "spentCredits",
        sort: "asc",
        width: 100,
      },
      {
        label: "Product Info",
        field: "productInfo",
        sort: "asc",
        width: 100,
      },
      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 100,
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 100,
      },
    ],
  });

  const id =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  // useEffect(() => {
  //   const fetchUsersHistory = async () => {
  //     setLoading(true);
  //     const results = await getSessionHistoryAdmin(id);

  //     console.log(results);

  //     setUsersHistoryData(results);
  //     setLoading(false);
  //   };
  //   fetchUsersHistory();
  // }, [id]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let mappedResult = [];
        const userHistory = await getSessionHistoryAdmin(id);
        mappedResult = userHistory.map((user) => {
          return {
            profileImage: (
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={
                    user?.profile_image?._url
                      ? user?.profile_image?._url
                      : noUserImage
                  }
                  alt={user?.username}
                  onClick={() =>
                    setImageModal({
                      isOpen: true,
                      image: user?.profile_image?._url
                        ? user?.profile_image?._url
                        : noUserImage,
                    })
                  }
                  style={{ width: "70px", height: "65px", cursor: "pointer" }}
                  className="img-thumbnail"
                />
              </div>
            ),
            username: user?.username,
            description: user?.desc,
            total_seconds: user?.total_seconds,
            total_minutes: user?.total_minutes,
            productInfo: user?.productInfo,
            date: new Date(user?.date).toLocaleString(),
            gain: user?.gain ? `$ ${user?.gain}` : "-",
            purchasedCredits:
              user?.type === "inapp" || user?.type === "sub"
                ? `${user?.amount} Coins`
                : "-",
            spentCredits:
              user?.type === "session" || user?.type === "tip"
                ? `${user?.amount} Coins`
                : "-",
            type: user?.type.toUpperCase(),
          };
        });
        mappedResult = mappedResult.filter((item) => item);
        setData((prevState) => ({
          ...prevState,
          rows: mappedResult,
        }));
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  return (
    <>
      <div className="page-content">
        <ImageModal imageModal={imageModal} setImageModal={setImageModal} />
        <Breadcrumbs
          title="Dashboard"
          previousUsers="Users"
          breadcrumbItem="User History"
        />

        <button
          type="button"
          className="btn btn-md btn-primary waves-effect waves-light mb-3"
          onClick={() => history.goBack()}
        >
          Back
        </button>

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {loading && <Loading loading={loading} />}
                {!loading && (
                  <MDBDataTable hover responsive striped bordered data={data} />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UserHistory;
