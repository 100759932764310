import { useState, useEffect } from "react";
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Loading from "../../../components/Common/Loading";
import { fetchCurrentUser } from "../../../service/parse-service";
import TutorEarningTr from "./TutorEarningTr";

const TutorEarningTable = ({
  tutorEarningData,
  loading,
  totalTipEarning,
  totalConversationEarning,
  totalEarning,
  handleOrder,
  order,
  history,
}) => {
  const [currentUserData, setCurrentUserData] = useState(null);

  useEffect(() => {
    fetchCurrentUser().then((currentUserData) => {
      setCurrentUserData(currentUserData);
    });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="table-rep-plugin">
        <div
          className="table-responsive mb-0 "
          data-pattern="priority-columns"
          style={{
            position: "relative",
            height: "60vh",
          }}
        >
          <Table className="table table-striped table-bordered">
            <Thead>
              <Tr>
                <Th>Username</Th>
                <Th
                  onClick={() => handleOrder("createdAt")}
                  style={{ cursor: "pointer" }}
                >
                  Created Date{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "createdAt" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "createdAt" &&
                          order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th
                  onClick={() => handleOrder("totalSeconds")}
                  style={{ cursor: "pointer" }}
                >
                  Conversation Time(Sec){" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "totalSeconds" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "totalSeconds" &&
                          order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                {currentUserData?.get("username") === "admin" && (
                  <Th
                    onClick={() => handleOrder("totalMinutes")}
                    style={{ cursor: "pointer" }}
                  >
                    Conversation Time(Min){" "}
                    <div className="d-inline-block">
                      <i
                        className="dripicons-arrow-thin-up"
                        style={{
                          opacity:
                            order.column === "totalMinutes" &&
                            order.direction === "desc"
                              ? 1
                              : 0.6,
                        }}
                      ></i>
                      <i
                        className="dripicons-arrow-thin-down"
                        style={{
                          opacity:
                            order.column === "totalMinutes" &&
                            order.direction === "asc"
                              ? 1
                              : 0.6,
                        }}
                      ></i>
                    </div>
                  </Th>
                )}

                <Th>Tip Earning</Th>
                <Th>Conversation Earning</Th>
                <Th
                  onClick={() => handleOrder("totalAmount")}
                  style={{ cursor: "pointer" }}
                >
                  Total Earnings{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "totalAmount" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "totalAmount" &&
                          order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 500,
                  height: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading />
              </div>
            ) : (
              <Tbody>
                {tutorEarningData.totalCount !== 0 && (
                  <Tr>
                    <Th></Th>
                    <Th></Th>
                    <Th></Th>
                    {currentUserData?.get("username") === "admin" && <Th></Th>}
                    <Th className="fw-bold">$ {totalTipEarning.toFixed(2)}</Th>
                    <Th className="fw-bold">
                      $ {totalConversationEarning.toFixed(2)}
                    </Th>
                    <Th className="fw-bold">$ {totalEarning.toFixed(2)}</Th>
                    <Th></Th>
                  </Tr>
                )}
                {tutorEarningData?.map((tutorEarning) => (
                  <TutorEarningTr
                    key={tutorEarning.objectId}
                    tutorEarning={tutorEarning}
                    currentUserData={currentUserData}
                    history={history}
                  />
                ))}
              </Tbody>
            )}
          </Table>
        </div>
      </div>
    </>
  );
};

export default TutorEarningTable;
