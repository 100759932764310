import { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Loading from "../../../components/Common/Loading";
import {
  getSingleSupport,
  userBan,
  userUnBan,
  deleteUser,
  toggleTicketStatus,
} from "../../../service/parse-service";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ImageModal from "../../../components/Modal/ImageModal";
import Swal from "sweetalert2";
import noUserImage from "../../../assets/images/users/no-user.png";

const TicketDetail = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [ticketButtonLoading, setTicketButtonLoading] = useState(false);
  const [ticket, setTicket] = useState(null);
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    image: "",
  });

  const id =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const ticketData = await getSingleSupport(id);
        if (ticketData) setTicket(ticketData);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  const handleBan = (userId) => {
    Swal.fire({
      title: "Are you sure you want to ban this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ban",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire("User Banned.");
          await userBan(userId);
          window.location.reload();
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  const handleUnban = (userId) => {
    Swal.fire({
      title: "Are you sure you want to unban this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Unban",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire("User Unbanned.");
          await userUnBan(userId);
          window.location.reload();
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  const handleDeleteUser = (userId) => {
    Swal.fire({
      title: "Are you sure you want to delete this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire("User deleted.");
          await deleteUser(userId);
          history.push("/ticket");
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  const handleChangeTicketStatus = (ticketId) => {
    Swal.fire({
      title: "Are you sure you want to confirm?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setTicketButtonLoading(true);
          await toggleTicketStatus(ticketId);
          setTicketButtonLoading(false);
          history.push("/ticket");
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <ImageModal imageModal={imageModal} setImageModal={setImageModal} />
      <div className="page-content">
        <Breadcrumbs
          title="Dashboard"
          previousTicket="Ticket"
          breadcrumbItem="Ticket Detail"
        />
        {loading && <Loading loading={loading} />}
        {!loading && (
          <>
            <button
              type="button"
              className="btn btn-md btn-primary waves-effect waves-light mb-3"
              onClick={() => history.goBack()}
            >
              Back
            </button>
            <Row>
              <Col lg={5}>
                <Card>
                  <CardBody>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Profile Image :</Col>
                      <Col lg={8}>
                        <img
                          src={
                            ticket?.user?.photos && ticket?.user?.photos[0]?.url
                              ? ticket?.user?.photos[0]?.url
                              : noUserImage
                          }
                          alt={ticket?.user?.nickname}
                          className="img-fluid rounded-circle"
                          onClick={() =>
                            setImageModal({
                              isOpen: true,
                              image:
                                ticket?.user?.photos &&
                                ticket?.user?.photos[0]?.url
                                  ? ticket?.user?.photos[0]?.url
                                  : noUserImage,
                            })
                          }
                          style={{
                            width: "100px",
                            height: "100px",
                            cursor: "pointer",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Username :</Col>
                      <Col lg={8}>{ticket?.user?.nickname || "-"}</Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Email :</Col>
                      <Col lg={8}>{ticket?.user?.email || "-"}</Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Ticket Date:</Col>
                      <Col lg={8}>
                        {new Date(ticket?.createdAt).toLocaleString() || "-"}
                      </Col>
                    </Row>
                    <div className="text-center mt-4">
                      <button
                        onClick={() => handleDeleteUser(ticket?.user?.objectId)}
                        type="button"
                        title="Delete User"
                        className="btn btn-danger waves-effect waves-light ms-2"
                      >
                        <i className="fas fa-solid fa-trash"></i>
                      </button>

                      <button
                        onClick={() => {
                          ticket?.user?.isBanned
                            ? handleUnban(ticket?.user?.objectId)
                            : handleBan(ticket?.user?.objectId);
                        }}
                        type="button"
                        title={
                          ticket?.user?.isBanned ? "Unban User" : "Ban User"
                        }
                        className="btn btn-danger waves-effect waves-light ms-2"
                      >
                        {ticket?.user?.isBanned ? (
                          <i className="fas fa-solid fa-lock-open"></i>
                        ) : (
                          <i className="fas fa-solid fa-lock"></i>
                        )}
                      </button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={7}>
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col lg={2}>Ticket Content:</Col>
                      <Col lg={10}>{ticket?.content || "-"}</Col>
                    </Row>
                    {ticket?.status === "pending" && (
                      <button
                        type="button"
                        title="Confirm"
                        className="btn btn-md btn-primary waves-effect waves-light d-flex justify-content-center align-items-center "
                        onClick={() =>
                          handleChangeTicketStatus(ticket?.objectId)
                        }
                      >
                        {ticketButtonLoading ? (
                          <div className="spinner-border spinner-border-sm text-light" />
                        ) : (
                          <i className="fas fa-solid fa-check"></i>
                        )}
                      </button>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default TicketDetail;
