import { useState } from "react";
import { Tr, Td } from "react-super-responsive-table";

const TutorDetailsTr = ({ tutorDetails, history }) => {
  const [isHoverName, setIsHoverName] = useState(false);

  const handleMouseEnter = () => {
    setIsHoverName(true);
  };

  const handleMouseLeave = () => {
    setIsHoverName(false);
  };

  const isHoverNameStyles = {
    cursor: isHoverName ? "pointer" : "default",
    textDecoration: isHoverName ? "underline" : "none",
    color: isHoverName ? "#007bff" : "",
  };

  return (
    <Tr>
      <Td
      // onClick={() => {
      //   history.push(`/users/${tutorDetails.tutor_id}`);
      // }}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
      // style={isHoverNameStyles}
      >
        {tutorDetails?.fullname}
      </Td>
      <Td>{new Date(tutorDetails?.startTime?.iso).toLocaleString()}</Td>
      <Td>{tutorDetails?.user?.nickname}</Td>

      <Td>{tutorDetails?.total_seconds}</Td>
      <Td>{tutorDetails?.total_minutes}</Td>
      <Td>
        {isNaN(tutorDetails?.total_minutes)
          ? "-"
          : `${tutorDetails?.total_minutes * 60} Coins`}
      </Td>
      <Td>{isNaN(tutorDetails?.amount) ? "-" : `$ ${tutorDetails?.amount}`}</Td>
      <Td>{tutorDetails?.isCall === true ? "Session" : "Tip"}</Td>
      <Td>{tutorDetails?.tipName}</Td>
    </Tr>
  );
};

export default TutorDetailsTr;
