import { useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Loading from "../../../components/Common/Loading";
import { Input, Button, Spinner, Form, FormGroup } from "reactstrap";
import { deleteAuthorityUsersFromAgent } from "../../../service/parse-service";

const AuthorityUsersTable = ({
  loading,
  authorityUsers,
  agentId,
  authorityUserVariable,
  setAuthorityUserVariable,
  performerVariable,
  setPerformerVariable,
  setAuSearchQuery,
}) => {
  const [selectedAuthorityUser, setSelectedAuthorityUser] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const tableHeight = loading ? "50vh" : "auto";

  const toggleSelectCheckbox = (user) => {
    if (selectedAuthorityUser.includes(user)) {
      setSelectedAuthorityUser(
        selectedAuthorityUser.filter((id) => id !== user)
      );
    } else {
      setSelectedAuthorityUser([...selectedAuthorityUser, user]);
    }
  };

  const handleDeleteAuthorityUsers = async () => {
    setBtnLoading(true);

    let selectedAuthorityUserIds = selectedAuthorityUser.map(
      (user) => user.objectId
    );

    await deleteAuthorityUsersFromAgent(agentId, selectedAuthorityUserIds);

    setAuthorityUserVariable(!authorityUserVariable);
    setPerformerVariable(!performerVariable);

    setSelectedAuthorityUser([]);
    setBtnLoading(false);
  };

  return (
    <>
      <div className="d-flex mb-3 justify-content-end align-items-center">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <FormGroup className="ms-auto">
            <Input
              type="text"
              placeholder="Search"
              onChange={(e) => {
                let target = e.target;
                setTimeout(() => {
                  setAuSearchQuery(target.value);
                }, 1000);
              }}
            />
          </FormGroup>
        </Form>
      </div>

      <div className="table-rep-plugin">
        <div
          className="table-responsive mb-0"
          data-pattern="priority-columns"
          style={{
            position: "relative",
            height: tableHeight,
          }}
        >
          <Table className="table table-striped table-bordered">
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Username</Th>
                <Th>Email</Th>
              </Tr>
            </Thead>

            {loading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 500,
                  height: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading />
              </div>
            ) : (
              <Tbody>
                {authorityUsers.map((user) => (
                  <Tr key={user.objectId}>
                    <Td>
                      <Input
                        type="checkbox"
                        onChange={() => toggleSelectCheckbox(user)}
                      />
                    </Td>
                    <Td>{user.nickname}</Td>
                    <Td>{user.email}</Td>
                  </Tr>
                ))}
              </Tbody>
            )}
          </Table>

          {!loading && (
            <div className="d-flex justify-content-center">
              <Button
                color="danger"
                onClick={handleDeleteAuthorityUsers}
                disabled={selectedAuthorityUser.length === 0}
              >
                {btnLoading ? (
                  <Spinner size="sm" color="light" />
                ) : (
                  "Delete Authority Users"
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AuthorityUsersTable;
