import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../../assets/scss/datatables.scss";
import { getUsersData } from "../../service/parse-service";
import AgentsTable from "./components/AgentsTable";
import TablePagination from "../../components/TablePagination";
import ImageModal from "../../components/Modal/ImageModal";
import CreateAgentModal from "./components/CreateAgentModal";

const Agents = () => {
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    image: "",
  });
  const [totalBoughtCredit, setTotalBoughtCredit] = useState(0);
  const [totalSpendCredit, setTotalSpendCredit] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [order, setOrder] = useState({
    column: "",
    direction: "",
  });
  const [isOpenCreateAgentModal, setIsOpenCreateAgentModal] = useState(false);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);

  const handleOrder = (column) => {
    let direction = "asc";
    if (order.column === column && order.direction === "asc") {
      direction = "desc";
    }
    setOrder({ column, direction });
  };

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);

      let results = await getUsersData(
        currentPage,
        usersPerPage,
        searchQuery,
        order,
        "agentsPage"
      );

      if (currentPage > 1 && results.users.length === 0) {
        setCurrentPage(1);
      }

      setTotalUsers(results.totalCount);
      setUsersData(results.users);

      let value1 = 0;
      let value2 = 0;
      let value3 = 0;

      results.users.forEach((item) => {
        if (item.bought_credit) {
          value1 += item?.bought_credit;
        }
        if (item.spend_credit) {
          value2 += item?.spend_credit;
        }
        if (item.balance) {
          value3 += item.balance;
        }
      });

      setTotalBoughtCredit(value1);
      setTotalSpendCredit(value2);
      setTotalBalance(value3);

      setLoading(false);
    };
    fetchUsers();
  }, [searchQuery, currentPage, usersPerPage, order]);

  return (
    <>
      <ToastContainer />
      <ImageModal imageModal={imageModal} setImageModal={setImageModal} />
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Agents" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>Agents</CardTitle>
                <div className="d-flex mb-3 justify-content-between align-items-center">
                  <span>
                    {searchQuery && (
                      <span>
                        Search results for:{" "}
                        <span className="fw-bold">{searchQuery}</span>
                      </span>
                    )}
                  </span>

                  <div className="d-flex gap-4">
                    {/* create button */}
                    <Button
                      color="primary"
                      className="btn btn-primary waves-effect waves-light"
                      onClick={() => setIsOpenCreateAgentModal(true)}
                    >
                      Create Agent
                    </Button>

                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <FormGroup className="ms-auto">
                        <Input
                          type="text"
                          placeholder="Search"
                          onChange={(e) => {
                            let target = e.target;
                            setTimeout(() => {
                              setSearchQuery(target.value);
                            }, 1000);
                          }}
                        />
                      </FormGroup>
                    </Form>
                  </div>
                </div>

                <AgentsTable
                  usersData={usersData}
                  loading={loading}
                  handleOrder={handleOrder}
                  order={order}
                  setImageModal={setImageModal}
                />

                <TablePagination
                  dataPerPage={usersPerPage}
                  totalData={totalUsers}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {/* Create Agent Modal */}
      <CreateAgentModal
        isOpen={isOpenCreateAgentModal}
        setIsOpen={setIsOpenCreateAgentModal}
        setUsersData={setUsersData}
      />
    </>
  );
};

export default Agents;
