import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../../assets/scss/datatables.scss";
import { getSupport } from "../../service/parse-service";
import ImageModal from "../../components/Modal/ImageModal";
import TicketTable from "./components/TicketTable";
import TablePagination from "../../components/TablePagination";

const Ticket = ({ history, location }) => {
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [ticketStatusFilter, setTicketStatusFilter] = useState("pending");
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    image: "",
  });
  const [ticketsData, setTicketsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [order, setOrder] = useState({
    column: "",
    direction: "",
  });
  // Pagination
  const [currentPage, setCurrentPage] = useState();
  const [ticketsPerPage] = useState(10);
  const [totalTickets, setTotalTickets] = useState(0);

  const handleOrder = (column) => {
    let direction = "asc";
    if (order.column === column && order.direction === "asc") {
      direction = "desc";
    }
    setOrder({ column, direction });
  };

  useEffect(() => {
    if (currentPage === undefined) return;
    const fetchTickets = async () => {
      setLoading(true);
      const results = await getSupport(
        ticketStatusFilter,
        currentPage,
        ticketsPerPage,
        searchQuery,
        order
      );

      setTicketsData(results.tickets);
      setTotalTickets(results.totalCount);
      setLoading(false);
    };
    fetchTickets();
  }, [ticketStatusFilter, currentPage, ticketsPerPage, searchQuery, order]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleStatusSelect = (status) => {
    setTicketStatusFilter(status);
  };

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <ImageModal imageModal={imageModal} setImageModal={setImageModal} />
        <Breadcrumbs title="Dashboard" breadcrumbItem="Ticket" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle className="mb-3">Ticket</CardTitle>
                <div className="d-flex justify-content-start mb-3">
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    className="me-2"
                  >
                    <DropdownToggle color="primary">
                      {ticketStatusFilter === "pending"
                        ? "Not solved"
                        : "Solved"}
                      <i className="fas fa-caret-down ms-2"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => handleStatusSelect("confirmed")}
                      >
                        Solved
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleStatusSelect("pending")}
                      >
                        Not solved
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                    className="ms-auto"
                  >
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => {
                          let target = e.target;
                          setTimeout(() => {
                            setSearchQuery(target.value);
                          }, 1000);
                        }}
                      />
                    </FormGroup>
                  </Form>
                </div>

                <TicketTable
                  ticketsData={ticketsData}
                  loading={loading}
                  setImageModal={setImageModal}
                  order={order}
                  handleOrder={handleOrder}
                  history={history}
                />

                <TablePagination
                  dataPerPage={ticketsPerPage}
                  totalData={totalTickets}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  history={history}
                  location={location}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Ticket;
