import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Loading from "../../../components/Common/Loading";
import { Link } from "react-router-dom";

const AgentsTable = ({ usersData, loading }) => {
  return (
    <>
      <div className="table-rep-plugin">
        <div
          className="table-responsive mb-0 "
          data-pattern="priority-columns"
          style={{
            position: "relative",
            height: "100vh",
          }}
        >
          <Table className="table table-striped table-bordered">
            <Thead>
              <Tr>
                <Th>Username</Th>
                <Th>Email</Th>
                <Th>Agents Count</Th>
                <Th>Monthly Call</Th>
                <Th>Monthly Revenue</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 500,
                  height: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading />
              </div>
            ) : (
              <Tbody>
                {usersData?.map((user, index) => (
                  <Tr key={index}>
                    <Td>{user.nickname}</Td>
                    <Td>{user.email}</Td>
                    <Td>{user.authority_users.length}</Td>
                    <Td>{user.monthly_call}</Td>
                    <Td>{user.monthly_revenue}</Td>
                    <Td>
                      <Link to={`/agents/${user.objectId}`}>
                        <button
                          type="button"
                          title="Detail"
                          className="btn btn-md btn-primary waves-effect waves-light d-flex justify-content-center align-items-center "
                        >
                          <i className="bx bxs-show"></i>
                        </button>
                      </Link>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            )}
          </Table>
        </div>
      </div>
    </>
  );
};

export default AgentsTable;
