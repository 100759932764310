import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Col, Row, Form } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Loading from "../../../components/Common/Loading";
import {
  getSingleUser,
  deleteUser,
  userBan,
  userUnBan,
  deletePhoto,
} from "../../../service/parse-service";
import Swal from "sweetalert2";

const UserDetail = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({}); // eslint-disable-line no-unused-vars

  const id =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  const fetchSingleUser = async () => {
    setLoading(true);
    try {
      let result = await getSingleUser(id);
      setUserInfo(result);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSingleUser();
    //eslint-disable-next-line
  }, [id]);

  const handleDeleteUser = (userId) => {
    Swal.fire({
      title: "Are you sure you want to delete this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire("User deleted.");
          await deleteUser(userId);
          history.goBack();
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  const handleBan = (userId) => {
    Swal.fire({
      title: "Are you sure you want to ban this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ban",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire("User Banned.");
          await userBan(userId);
          window.location.reload();
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  const handleUnban = (userId) => {
    Swal.fire({
      title: "Are you sure you want to unban this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Unban",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire("User Unbanned.");
          await userUnBan(userId);
          window.location.reload();
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  const handleDeletePhoto = (index, userId) => {
    Swal.fire({
      title: "Are you sure you want to delete this photo?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deletePhoto(index, userId);
          setUserInfo((prevState) => {
            let photos = [...prevState.photos];
            photos.splice(index, 1);
            return { ...prevState, photos };
          });
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="User Details" />
        {loading && <Loading loading={loading} />}
        {!loading && (
          <>
            <button
              type="button"
              className="btn btn-md btn-primary waves-effect waves-light mb-3"
              onClick={() => history.goBack()}
            >
              Back
            </button>

            <Form>
              <Row>
                <Col lg={6}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg={4}>
                          <span className="fw-bold">Profile Images:</span>
                        </Col>
                        <Col lg={8}>
                          <div
                            className="d-flex flex-row"
                            style={{
                              overflowX: "auto",
                              whiteSpace: "nowrap",
                              width: "100%",
                            }}
                          >
                            {userInfo.photos &&
                              userInfo.photos.map((photo, index) => (
                                <div
                                  key={index}
                                  className="d-flex flex-column"
                                  style={{
                                    marginRight: "15px",
                                    position: "relative",
                                  }}
                                >
                                  <img
                                    className="mb-5"
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                      borderRadius: "20px",
                                    }}
                                    src={photo?.url}
                                    alt={photo.name}
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm position-absolute top-0 end-0"
                                    onClick={() =>
                                      handleDeletePhoto(
                                        index,
                                        userInfo?.objectId
                                      )
                                    }
                                  >
                                    <span>X</span>
                                  </button>
                                </div>
                              ))}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card>
                    <CardBody>
                      <ul className="ps-3 list-inline d-flex flex-column gap-2">
                        <li>
                          <span className="fw-bold">Username: </span>
                          <span>{userInfo.nickname || "-"}</span>
                        </li>
                        <li>
                          <span className="fw-bold">Gender: </span>
                          <span>{userInfo.gender?.name || "-"}</span>
                        </li>
                        <li>
                          <span className="fw-bold">Age: </span>
                          <span>{userInfo.age || "-"}</span>
                        </li>
                        <li>
                          <span className="fw-bold">Mail: </span>
                          <span>{userInfo.email || "-"}</span>
                        </li>
                        <li>
                          <span className="fw-bold">Tags: </span>
                          {userInfo.tags?.map((tag) => tag).join(", ") || "-"}
                        </li>
                        <li>
                          <span className="fw-bold">Available: </span>
                          {userInfo.available ? "Yes" : "No"}
                        </li>
                        <li>
                          <span className="fw-bold">Platform: </span>
                          <span>{userInfo.platform || "-"}</span>
                        </li>
                        <li>
                          <span className="fw-bold">Device Language: </span>
                          <span>{userInfo.device_language || "-"}</span>
                        </li>
                        <li>
                          <span className="fw-bold">Banned: </span>
                          {userInfo.isBanned ? "Yes" : "No"}
                        </li>
                        <li>
                          <span className="fw-bold">Profile Updated: </span>
                          {userInfo.isProfileUpdated ? "Yes" : "No"}
                        </li>
                        <li>
                          <span className="fw-bold">Tutor: </span>
                          {userInfo.isTutor ? "Yes" : "No"}
                        </li>
                        <li>
                          <span className="fw-bold">Premium Plan: </span>
                          {userInfo.premiumPlan ? "Yes" : "No"}
                        </li>
                        <li>
                          <span className="fw-bold">Rating: </span>
                          <span>{userInfo.rating}</span>
                        </li>
                      </ul>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
            <div className="text-center mt-4">
              <button
                onClick={() => handleDeleteUser(userInfo?.objectId)}
                type="button"
                title="Delete User"
                className="btn btn-danger waves-effect waves-light ms-2"
              >
                <i className="fas fa-solid fa-trash"></i>
              </button>

              <button
                onClick={() => {
                  userInfo?.isBanned
                    ? handleUnban(userInfo?.objectId)
                    : handleBan(userInfo?.objectId);
                }}
                type="button"
                title={userInfo?.isBanned ? "Unban User" : "Ban User"}
                className="btn btn-danger waves-effect waves-light ms-2"
              >
                {userInfo?.isBanned ? (
                  <i className="fas fa-solid fa-lock-open"></i>
                ) : (
                  <i className="fas fa-solid fa-lock"></i>
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserDetail;
