import { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { createNewAgent } from "../../../service/parse-service";
import { toast } from "react-toastify";

const CreateAgentModal = ({ isOpen, setIsOpen, setUsersData }) => {
  const [formValues, setFormValues] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errMessage, setErrMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
    setFormValues({
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
    });
    setErrMessage("");
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormValues({ ...formValues, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrMessage("");

    if (
      formValues.username.length === 0 ||
      formValues.email.length === 0 ||
      formValues.password.length === 0 ||
      formValues.confirmPassword.length === 0
    ) {
      setErrMessage("Please fill in all fields");
      return;
    }

    if (!formValues.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      setErrMessage("Please enter a valid email");
      return;
    }

    if (formValues.password !== formValues.confirmPassword) {
      setErrMessage("Passwords do not match");
      return;
    }

    setLoading(true);

    const result = await createNewAgent(formValues);

    if (result.success) {
      setUsersData((prev) => [result.user?.toJSON(), ...prev]);
      toggle();
      toast.success(result.msg);
      setErrMessage("");
      setLoading(false);
    } else {
      setErrMessage(result.msg);
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} centered toggle={toggle}>
        <ModalHeader toggle={toggle}>Create Agent</ModalHeader>
        <ModalBody>
          <p className="text-danger">{errMessage}</p>
          <Form className="d-flex flex-column gap-3">
            <FormGroup>
              <Label for="username">Username</Label>
              <Input
                type="text"
                id="username"
                placeholder="Enter username"
                required
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                id="email"
                placeholder="Enter email"
                required
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                type="password"
                id="password"
                placeholder="Enter password"
                required
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="confirmPassword">Confirm Password</Label>
              <Input
                type="password"
                id="confirmPassword"
                placeholder="Confirm password"
                required
                onChange={handleChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit} disabled={loading}>
            {loading ? <Spinner size="sm" /> : "Create"}
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CreateAgentModal;
