import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getTutorEarning } from "../../../service/parse-service";
import { ToastContainer } from "react-toastify";
import { CSVLink } from "react-csv";
import TablePagination from "../../../components/TablePagination";
import TutorDetailsTable from "./TutorDetailsTable";
import { DateRange } from "react-date-range";

const TutorsDetails = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [tutorDetailsData, setTutorDetailsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [order, setOrder] = useState({
    column: "",
    direction: "",
  });
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [tutorDetailsPerPage] = useState(10);
  const [totalTutorDetails, setTotalTutorDetails] = useState(0);

  const handleOrder = (column) => {
    let direction = "asc";
    if (order.column === column && order.direction === "asc") {
      direction = "desc";
    }
    setOrder({ column, direction });
  };

  const id =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  useEffect(() => {
    const fetchTutorEarning = async () => {
      setLoading(true);

      let results = await getTutorEarning(
        id,
        order,
        currentPage,
        tutorDetailsPerPage,
        searchQuery,
        state
      );

      setTutorDetailsData(results);
      setTotalTutorDetails(results.totalCount);

      let value1 = 0;
      results.jsonItems.forEach((item) => {
        if (item.amount) {
          value1 += item?.amount;
        }
      });

      setTotalEarnings(value1);

      setLoading(false);
    };
    fetchTutorEarning();
  }, [order, currentPage, searchQuery, id, tutorDetailsPerPage, state]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const resetDateFilter = () => {
    setState([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);

    setOrder({
      column: "",
      direction: "",
    });
  };

  const currentYear = new Date().getFullYear();

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Breadcrumbs
          title="Dashboard"
          previousTutors="Performers"
          breadcrumbItem="Performer Conversations Detail"
        />

        <button
          type="button"
          className="btn btn-md btn-primary waves-effect waves-light mb-3"
          onClick={() => history.goBack()}
        >
          Back
        </button>

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {/* <div className="d-flex justify-content-end mb-2">
                  <CSVLink data={csvData} filename={"users.csv"}>
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      <i className="bx bx-download font-size-16 align-middle me-2"></i>{" "}
                      Download
                    </button>
                  </CSVLink>
                </div> */}
                <div className="d-flex justify-content-start mb-3">
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    className="me-2"
                  >
                    <DropdownToggle color="primary">
                      {state[0].startDate === null
                        ? "Date"
                        : `${state[0].startDate.toLocaleDateString()} - ${state[0].endDate.toLocaleDateString()}`}
                      <i className="fas fa-caret-down mx-1"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DateRange
                        editableDateInputs={true}
                        onChange={(item) => setState([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                        minDate={new Date(`01-01-${currentYear}`)}
                        maxDate={new Date(`12-31-${currentYear}`)}
                      />
                    </DropdownMenu>
                  </Dropdown>
                  <Button color="primary" onClick={resetDateFilter}>
                    <span className="me-2">Reset</span>
                    <i className="fas fa-redo"></i>
                  </Button>
                  <Form
                    className="ms-auto"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => {
                          let target = e.target;
                          setTimeout(() => {
                            setSearchQuery(target.value);
                          }, 1000);
                        }}
                      />
                    </FormGroup>
                  </Form>
                </div>

                <TutorDetailsTable
                  tutorDetailsData={tutorDetailsData}
                  loading={loading}
                  handleOrder={handleOrder}
                  order={order}
                  history={history}
                  totalEarnings={totalEarnings}
                />

                <TablePagination
                  dataPerPage={tutorDetailsPerPage}
                  totalData={totalTutorDetails}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TutorsDetails;
