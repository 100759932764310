import { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

import { ToastContainer } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  getAuthorityUsersFromUserTable,
  getAgentsAuthorityUsers,
  getTutorsForAgentDetailsPage,
} from "../../service/parse-service";
import AgentDetailsPagination from "./components/AgentDetailsPagination";
import TutorsTable from "./components/TutorsTable";
import AuthorityUsersTable from "./components/AuthorityUsersTable";

const AgentDetails = ({ history }) => {
  // Authority Users
  const [authorityUsers, setAuthorityUsers] = useState([]);
  const [auCurrentPage, setAuCurrentPage] = useState(1);
  const [auPerPage] = useState(10);
  const [totalAuthorityUsers, setTotalAuthorityUsers] = useState(0);
  const [auLoading, setAuLoading] = useState(false);
  const [auSearchQuery, setAuSearchQuery] = useState("");
  // Performers
  const [performers, setPerformers] = useState([]);
  const [pCurrentPage, setPCurrentPage] = useState(1);
  const [pPerPage] = useState(10);
  const [totalPerformers, setTotalPerformers] = useState(0);
  const [pLoading, setPLoading] = useState(false);
  const [authorityUserVariable, setAuthorityUserVariable] = useState(false);
  const [performerVariable, setPerformerVariable] = useState(false);
  const [pSearchQuery, setPSearchQuery] = useState("");

  const id =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  useEffect(() => {
    const fetchAuthorityUsers = async () => {
      setAuLoading(true);
      const results = await getAgentsAuthorityUsers(id);

      const authorityUsers = await getAuthorityUsersFromUserTable(
        results,
        auCurrentPage,
        auPerPage,
        auSearchQuery
      );

      if (authorityUsers?.auList?.length === 0 && auCurrentPage > 1) {
        setAuCurrentPage(auCurrentPage - 1);
      }

      setAuthorityUsers(authorityUsers?.auList);
      setTotalAuthorityUsers(authorityUsers?.totalCount);
      setAuLoading(false);
    };
    fetchAuthorityUsers();
  }, [id, auCurrentPage, auPerPage, authorityUserVariable, auSearchQuery]);

  useEffect(() => {
    const fetchTutors = async () => {
      setPLoading(true);
      const tutors = await getTutorsForAgentDetailsPage(
        pCurrentPage,
        pPerPage,
        pSearchQuery
      );

      if (tutors?.tutors?.length === 0 && pCurrentPage > 1) {
        setPCurrentPage(pCurrentPage - 1);
      }

      setPerformers(tutors.tutors);

      setTotalPerformers(tutors.totalCount);
      setPLoading(false);
    };
    fetchTutors();
  }, [pCurrentPage, pPerPage, pSearchQuery, performerVariable]);

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Agent Details" />
        <button
          type="button"
          className="btn btn-md btn-primary waves-effect waves-light mb-3"
          onClick={() => history.goBack()}
        >
          Back
        </button>
        <Row>
          <Col className="col-6">
            <Card>
              <CardBody>
                <CardTitle className="mb-2">Performers</CardTitle>

                <TutorsTable
                  performers={performers}
                  loading={pLoading}
                  agentId={id}
                  authorityUserVariable={authorityUserVariable}
                  setAuthorityUserVariable={setAuthorityUserVariable}
                  setPSearchQuery={setPSearchQuery}
                  performerVariable={performerVariable}
                  setPerformerVariable={setPerformerVariable}
                />

                <AgentDetailsPagination
                  dataPerPage={pPerPage}
                  totalData={totalPerformers}
                  currentPage={pCurrentPage}
                  setCurrentPage={setPCurrentPage}
                />
              </CardBody>
            </Card>
          </Col>
          <Col className="col-6">
            <Card>
              <CardBody>
                <CardTitle className="mb-2">Authority Users</CardTitle>
                <AuthorityUsersTable
                  authorityUsers={authorityUsers}
                  loading={auLoading}
                  agentId={id}
                  authorityUserVariable={authorityUserVariable}
                  setAuthorityUserVariable={setAuthorityUserVariable}
                  performerVariable={performerVariable}
                  setPerformerVariable={setPerformerVariable}
                  setAuSearchQuery={setAuSearchQuery}
                />

                <AgentDetailsPagination
                  dataPerPage={auPerPage}
                  totalData={totalAuthorityUsers}
                  currentPage={auCurrentPage}
                  setCurrentPage={setAuCurrentPage}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AgentDetails;
