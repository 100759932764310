import { useEffect, useState } from "react";
import {
  getTutors,
  getTutorEarningBySpecificDate,
} from "../service/parse-service";
import Loading from "./Common/Loading";

export const DashboardInfo = () => {
  const [tutorData, setTutorData] = useState([]);
  const [tutorEarnings, setTutorEarnings] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const results = await getTutors();

      const tutorEarning = await getTutorEarningBySpecificDate(new Date("2020-01-01"),new Date());
      if (results) setTutorData(results.tutors);
      if (tutorEarning) setTutorEarnings(tutorEarning.activities);
      setLoading(false);
    };
    fetchData();
  }, []);

  return loading ? (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Loading />
    </div>
  ) : (
    <div>
      <p>
        Total number of performers : <span>{tutorData?.length || 0}</span>
      </p>
      <p>
        Total number of calls :{" "}
        {tutorData?.reduce(
          (a, item) => a + (item?.numberOfCallsAnswered || 0),
          0
        )}
      </p>
      <p>
        Total number of missed calls :{" "}
        {tutorData?.reduce((a, item) => a + (item?.numberOfCallsMissed || 0), 0)}
      </p>
      <p>
        Total amount paid :{" "}
        {tutorData?.reduce((a, item) => a + (item?.earning || 0), 0)}{" "}
      </p>
      <p>
        Tutor will receive :{" "}
        {tutorEarnings?.reduce((a, item) => a + (item?.amount || 0), 0).toFixed(2)}{" "}
      </p>
    </div>
  );
};
