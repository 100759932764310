import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../../assets/scss/datatables.scss";
import { getTutorEarningReport } from "../../service/parse-service";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import TutorEarningTable from "./components/TutorEarningTable";
import TablePagination from "../../components/TablePagination";

const TutorEarning = ({ history, location }) => {
  const [loading, setLoading] = useState(false);
  const [tutorEarningData, setTutorEarningData] = useState([]);
  const [totalTipEarning, setTotalTipEarning] = useState(0);
  const [totalConversationEarning, setTotalConversationEarning] = useState(0);
  const [totalEarning, setTotalEarning] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [order, setOrder] = useState({
    column: "",
    direction: "",
  });

  // Pagination
  const [currentPage, setCurrentPage] = useState();
  const [tutorEarningPerPage] = useState(10);
  const [totalTutorEarning, setTotalTutorEarning] = useState(0);

  const handleOrder = (column) => {
    let direction = "asc";
    if (order.column === column && order.direction === "asc") {
      direction = "desc";
    }
    setOrder({ column, direction });
  };

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (currentPage === undefined) return;
    const fetchTutorEarning = async () => {
      setLoading(true);

      let results = await getTutorEarningReport(
        state,
        order,
        currentPage,
        tutorEarningPerPage,
        searchQuery
      );

      setTutorEarningData(results.items);
      setTotalTutorEarning(results.totalCount);

      let value1 = 0;
      let value2 = 0;
      let value3 = 0;

      results.items.forEach((result) => {
        result?.reason?.forEach((item, index) => {
          if (item === "Tip") {
            value1 += result?.amount[index];
          } else {
            value2 += result?.amount[index];
          }
        });
        if (result.totalAmount) {
          value3 += result.totalAmount;
        }
      });

      setTotalTipEarning(value1);
      setTotalConversationEarning(value2);
      setTotalEarning(value3);

      setLoading(false);
    };
    fetchTutorEarning();
  }, [state, order, currentPage, tutorEarningPerPage, searchQuery]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const resetDateFilter = () => {
    setState([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);

    setOrder({
      column: "",
      direction: "",
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Performer Earnings" />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle className="mb-3">Performer Earnings</CardTitle>
                <div className="d-flex justify-content-start mb-3">
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    className="me-2"
                  >
                    <DropdownToggle color="primary">
                      {state[0].startDate === null
                        ? "Date"
                        : `${state[0].startDate.toLocaleDateString()} - ${state[0].endDate.toLocaleDateString()}`}
                      <i className="fas fa-caret-down mx-1"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DateRange
                        editableDateInputs={true}
                        onChange={(item) => setState([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                        minDate={new Date(`01-01-${currentYear}`)}
                        maxDate={new Date(`12-31-${currentYear}`)}
                      />
                    </DropdownMenu>
                  </Dropdown>
                  <button
                    className="border border-white rounded btn-primary"
                    onClick={resetDateFilter}
                  >
                    <span className="me-2">Reset</span>
                    <i className="fas fa-redo"></i>
                  </button>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                    className="ms-auto"
                  >
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => {
                          let target = e.target;
                          setTimeout(() => {
                            setSearchQuery(target.value);
                          }, 1000);
                        }}
                      />
                    </FormGroup>
                  </Form>
                </div>

                <TutorEarningTable
                  tutorEarningData={tutorEarningData}
                  totalTipEarning={totalTipEarning}
                  totalConversationEarning={totalConversationEarning}
                  totalEarning={totalEarning}
                  loading={loading}
                  handleOrder={handleOrder}
                  order={order}
                  history={history}
                />

                <TablePagination
                  dataPerPage={tutorEarningPerPage}
                  totalData={totalTutorEarning}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  history={history}
                  location={location}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TutorEarning;
